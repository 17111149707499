<template>
	<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
		<template slot="action" slot-scope="{ columnData }">
			<a @click="loanInfo(columnData.loanId)">{{ $t('page.edit') }}</a>
			<a-divider type="vertical" />
			<a @click="checkDetail(columnData.loanId)">{{ $t('page.checkSubmit') }}</a>
		</template>
	</search-list-table>
</template>
<script>
import { apiEmergencyDisburseInfo } from '@/api/emergencyLoan'
export default {
	data() {
		return {}
	},
	computed: {
		formSearchList() {
			return [
				{
					label: 'table.applyInfo.cloums.caseId',
					type: 'input',
					validateField: ['caseId']
				},
				{
					label: 'table.applyInfo.cloums.caseStatus',
					type: 'select',
					validateField: ['processStatuses'],
					selectOption: ['disburse_info_input', 'disburse_info_check_fail'],
					prefix: 'table.applyInfo.recordCloums.nodeStatus'
				},
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['name']
				},
				{
					label: 'table.CustomerManagement.fromdata.licensePlateNumber',
					type: 'input',
					validateField: ['registrationNumber']
				},
				{
					label: 'table.applyInfo.cloums.IdNumber',
					type: 'input',
					validateField: ['identityNum']
				},
				{
					label: 'table.applyInfo.cloums.mobile',
					type: 'input',
					validateField: ['mobile']
				},
				{
					label: 'table.applyInfo.cloums.creator',
					type: 'input',
					validateField: ['creator']
				},
				{
					label: 'table.applyInfo.cloums.createTime',
					type: 'range-picker',
					validateField: ['createTime', { initialValue: [] }]
				}
			]
		},
		columns() {
			return [
				{
					// 案件ID
					title: 'No.',
					scopedSlots: { customRender: 'serial_number' },
					width: 80,
					align: 'center'
				},
				{
					// 案件ID
					title: this.$t('table.applyInfo.cloums.caseId'),
					dataIndex: 'id',
					align: 'center',
					customRender: (v, o) => {
						return o.casePrefix + '-' + o.caseId
					}
				},
				{
					// 状态
					title: this.$t('table.applyInfo.cloums.status'),
					dataIndex: 'processStatus',
					align: 'center',
					customRender: (v, o) => {
						return this.$t(`table.applyInfo.recordCloums.nodeStatus.${v}`)
					}
				},
				{
					// 用户名
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'firstName',
					align: 'center',
					customRender: (v, o) => (v ? v : '') + ' ' + (o.middleName ? o.middleName : '') + ' ' + (o.lastName ? o.lastName : '')
				},
				{
					// 车牌号
					title: this.$t('table.CustomerManagement.fromdata.licensePlateNumber'),
					dataIndex: 'regNo',
					align: 'center',
					customRender: (v) => (v ? v : '-')
				},
				{
					// 身份ID
					title: this.$t('table.applyInfo.cloums.IdNumber'),
					dataIndex: 'identityNum',
					align: 'center'
				},
				{
					// 申请金额
					title: this.$t('table.applyInfo.cloums.loanApproved'),
					dataIndex: 'applyAmount',
					align: 'center',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					// 创建人ID
					title: this.$t('table.applyInfo.cloums.creator'),
					dataIndex: 'creatorName',
					align: 'center'
				},
				{
					// 创建时间
					title: this.$t('table.applyInfo.cloums.createTime'),
					dataIndex: 'createTime',
					align: 'center'
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 250,
					fixed: 'right'
				}
			]
		}
	},
	methods: {
		initDataSource(initParams) {
			return apiEmergencyDisburseInfo(initParams)
		},
		loanInfo(id) {
			this.$router.push({
				path: 'loanInfo',
				query: {
					id,
					from: this.$route.path
				}
			})
		},
		checkDetail(id) {
			this.$router.push({
				path: 'detail',
				query: {
					id,
					from: this.$route.path
				}
			})
		}
	}
}
</script>
<style scoped lang="less"></style>
